import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import UserContextProvider from './context/auth/UserProvider';
import NotificationsContexProvider from './context/notifications/NotificationsProvider';
import CacheBuster from './views/CacheBuster';
import ErrorBoundary from './components/common/ErrorBoundary';

const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App'));

const root = createRoot(document.getElementById('root'));

root.render(
  <CacheBuster>
    <ErrorBoundary>
      <Router>
        <UserContextProvider>
          <NotificationsContexProvider>
            <Suspense fallback={<div className="loading" />}>
              <App />
            </Suspense>
          </NotificationsContexProvider>
        </UserContextProvider>
      </Router>
    </ErrorBoundary>
  </CacheBuster>,
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
