import validator from 'validator';
import { testStatus } from './defaultValues';
import NotificationManager from '../components/common/react-notifications/NotificationManager';

export const validateLogin = ({ email, password }) => {
  const fieldsErrors = {};
  if (password.length < 6) fieldsErrors.password = 'Password must be 6 characters or longer.';
  if (validator.isEmpty(password)) fieldsErrors.password = 'Please enter your password.';
  if (!validator.isEmail(email)) fieldsErrors.email = 'Invalid email address';
  if (validator.isEmpty(email)) fieldsErrors.email = 'Please enter your email address';
  return Object.keys(fieldsErrors).length ? fieldsErrors : false;
};

export const validateClinic = (clinic) => {
  const fieldsErrors = {};
  if (!clinic.name) fieldsErrors.name = 'Name is required field.';
  if (validator.isEmpty(clinic.email) || clinic.email.split(',').some((email) => !validator.isEmail(email.trim()))) {
    fieldsErrors.email = 'Please enter client email address in format: yourname@example.com';
  }
  for (let i = 1; i <= 3; i++) {
    if (clinic[`phone${i}`] && !validator.isMobilePhone(clinic[`phone${i}`])) {
      fieldsErrors[`phone${i}`] = 'Please eneter phone number in format: 111-222-3333';
    }
  }
  return Object.keys(fieldsErrors).length ? fieldsErrors : false;
};

export const validateUser = (user) => {
  const fieldsErrors = {};
  if (!user.email || !validator.isEmail(user.email)) {
    fieldsErrors.email = 'Please enter user email address in format: yourname@example.com';
  }
  if (!user.fname || !(user.fname && user.fname.replace(/\s+/g, ' ').trim())) {
    fieldsErrors.fname = 'Please enter correct first name';
  }
  if (!user.lname || !(user.lname && user.lname.replace(/\s+/g, ' ').trim())) {
    fieldsErrors.lname = 'Please enter correct last name';
  }
  if (!user.role) {
    fieldsErrors.role = 'Please select correct role';
  }
  if (!user.UserClinics || user.UserClinics?.length === 0) {
    fieldsErrors.UserClinics = 'Please choose clinic';
  }
  return Object.keys(fieldsErrors).length ? fieldsErrors : false;
};

export const validatePasswordReset = (password, confirm) => {
  const fieldsErrors = {};
  if (password !== confirm) fieldsErrors.confirm = 'Password should be equal confirm password';
  if (!/.{6,}$/i.test(password)) fieldsErrors.password = 'Password must be 6 characters or longer.';
  if (!/^(?=.*[a-zA-Z]+.*)/i.test(password)) fieldsErrors.password = 'Password must contain at least 1 letter';
  if (!/^(?=.*[0-9]+.*)/i.test(password)) fieldsErrors.password = 'Password must contain at least 1 number';

  if (!password) fieldsErrors.password = 'Please enter your password';

  return Object.keys(fieldsErrors).length ? fieldsErrors : false;
};

export const statuses2fa = {
  DISABLED: 0,
  PHONE: 1,
  APP: 2,
};

export const handleGoogleAddresses = (address) => {
  const streetNumber = address.find((component) => component.types.find((type) => type === 'street_number'))?.long_name;
  const route = address.find((component) => component.types.find((type) => type === 'route'))?.long_name;
  const city = address.find((component) => component.types.find((type) => type === 'locality'))?.long_name;
  const state = address.find((component) => component.types.find((type) => type === 'administrative_area_level_1'))?.short_name;
  const zip = address.find((component) => component.types.find((type) => type === 'postal_code'))?.long_name;
  const street = `${streetNumber || ''} ${route || ''}`;
  const country = address.find((component) => component.types.find((type) => type === 'country'))?.long_name;
  return {
    street,
    city,
    state,
    zip,
    country,
  };
};

export const invalidateReasons = [
  'The sample is too degraded and cannot be analyzed reliably.',
  'The sample does not meet the preparation requirements and cannot be analyzed reliably. ',
  'There was not enough sample for this test, a result cannot be produced reliably.',
];

export const getTrackingUrl = (service, tracking_number) => {
  switch (service) {
    case 'fedEx':
      return `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${tracking_number}&cntry_code=us&locale=en_US`;
    case 'ups':
      return `https://www.ups.com/track?tracknum=${tracking_number}`;
    default:
      return '';
  }
};

export const clinicInfoFields = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    showFormFeedback: true,
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    showFormFeedback: true,
  },
  {
    name: 'address',
    label: 'Address',
    type: 'text',
    showFormFeedback: false,
  },
  {
    name: 'city',
    label: 'City',
    type: 'text',
    showFormFeedback: false,
  },
  {
    name: 'state',
    label: 'State',
    type: 'text',
    showFormFeedback: false,
  },
  {
    name: 'zip',
    label: 'Zip',
    type: 'number',
    showFormFeedback: false,
  },
  {
    name: 'phone1',
    label: 'Phone 1',
    type: 'phone',
    showFormFeedback: true,
  },
  {
    name: 'phone2',
    label: 'Phone 2',
    type: 'phone',
    showFormFeedback: true,
  },
  {
    name: 'phone3',
    label: 'Phone 3',
    type: 'phone',
    showFormFeedback: true,
  },
];

export const customFieldType = {
  2: {
    label: 'Text', value: 2, key: 0, name: 'cf_type',
  },
  4: {
    label: 'Rich Text', value: 4, key: 1, name: 'cf_type',
  },
  8: {
    label: 'Media', value: 8, key: 2, name: 'cf_type',
  },
};

export const returnCustomFieldTypeArray = () => {
  const result = [];
  const keys = Object.keys(customFieldType);
  keys.forEach((key) => {
    result.push(customFieldType[key]);
  });
  return result;
};

export const patientGender = {
  LOW: {
    label: 'Male', value: 2, key: 0, name: 'gender',
  },
  NORMAL: {
    label: 'Female', value: 4, key: 1, name: 'gender',
  },
  UNKNOWN: {
    label: 'Unknown', value: 8, key: 2, name: 'gender',
  },
};

export const returnPatientGenderArray = () => {
  const result = [];
  const keys = Object.keys(patientGender);
  keys.forEach((key) => {
    result.push(patientGender[key]);
  });
  return result;
};

export const convertPatientGender = (key) => key && returnPatientGenderArray().find((gender) => gender.value === key);

/**
 * Converts a special format string 2y01m to number of month in integer
 * @param string value
 * @returns interger number of month
 */
export const ageToDob = (value) => {
  const ageYears = parseInt(value[0] + value[1], 10);
  let val = value[4] + value[5];
  if (val.length === 1 && val[0] > 1) {
    val = `0${val}`;
  }
  if (val > 11) {
    val = 11;
  }
  let ageMonth = parseInt(val, 10);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(ageMonth)) ageMonth = 0;
  return ageYears * 12 + ageMonth;
};

export const getSexNeuteredPair = (gender, neutered) => {
  if (!gender) return '';
  const genderInfo = returnPatientGenderArray().find((item) => item.value === gender);
  if (neutered) {
    return `${genderInfo.label} (${gender === 4 ? 'Spayed' : 'Neutered'})`;
  }
  return genderInfo.label;
};
export const neuteredOptions = [
  { value: true, label: 'Yes', name: 'neutered' },
  { value: false, label: 'No', name: 'neutered' },
];

/**
 * Accepts number of months eg:12 and converts to string format
 * @param integer months
 * @returns string conversion of year and month in eg: 0102  i.e is 1 year and 2 months
 */
export const dobToAge = (months) => {
  let yearsInAge = (Math.trunc(months / 12)).toString();
  let monthsInAge = (months % 12).toString();
  if (yearsInAge.length === 1) yearsInAge = `0${yearsInAge}`;
  if (monthsInAge.length === 1) monthsInAge = `0${monthsInAge}`;
  const value = yearsInAge + monthsInAge;
  return value.toString();
};

/**
 * Converts string's first letter to uppercase eg: james bond => James Bond
 * @param {*} str
 * @returns string
 */
export const ucwords = (str) => (`${str}`).replace(/^([a-z])|\s+([a-z])/g, ($1) => $1.toUpperCase());

export const testStatusSelectOption = (statusId) => {
  if (statusId) {
    let output = null;
    Object.keys(testStatus).forEach((item) => {
      if (testStatus[item] === statusId) {
        output = { value: testStatus[item], label: ucwords(item).replace('_', ' ') };
      }
    });
    return output;
  }

  const statusOutput = [];
  Object.keys(testStatus).map((item) => statusOutput.push({ value: testStatus[item], label: ucwords(item).replace('_', ' ') }));
  return statusOutput;
};

export const specialCharacterCheck = (text) => /[`!@#$%^&*()_+\-=[\]{};':"\\|,<>/?~]/.test(text);

export const testPriority = {
  LOW: {
    label: 'Low', value: 2, key: 0, name: 'priority',
  },
  NORMAL: {
    label: 'Normal', value: 4, key: 1, name: 'priority',
  },
  HIGH: {
    label: 'Stat', value: 8, key: 2, name: 'priority',
  },
};

export const returnTestPriorityArray = () => {
  const result = [];
  const keys = Object.keys(testPriority);
  keys.forEach((key) => {
    result.push(testPriority[key]);
  });
  return result;
};

export const convertTestPriority = (key) => key && returnTestPriorityArray().find((priority) => priority.value === key);

export const notificationMess = {
  created: (name) => `${name} created`,
  createdError: (name) => `${name} could not be created`,
  edited: (name) => `${name} edited`,
  editedError: (name) => `${name} could not be edited`,
  deleted: (name) => `${name} deleted`,
  deletedError: (name) => `${name} could not be deleted`,
};

export const notification = {
  createdSuccess: (name, mes = '') => NotificationManager.success(
    mes,
    notificationMess.created(name),
    3000,
    null,
    null,
    'filled',
  ),
  createdError: (name, mes = '') => NotificationManager.error(
    mes,
    notificationMess.createdError(name),
    3000,
    null,
    null,
    'filled',
  ),
  editedSuccess: (name, mes = '') => NotificationManager.success(
    mes,
    notificationMess.edited(name),
    3000,
    null,
    null,
    'filled',
  ),
  editedError: (name, mes = '') => NotificationManager.error(
    mes,
    notificationMess.editedError(name),
    3000,
    null,
    null,
    'filled',
  ),
  deletedSuccess: (name, mes = '') => NotificationManager.info(
    mes,
    notificationMess.deleted(name),
    3000,
    null,
    null,
    'filled',
  ),
  deletedError: (name, mes = '') => NotificationManager.error(
    mes,
    notificationMess.deletedError(name),
    3000,
    null,
    null,
    'filled',
  ),
  error: (name, mes = '') => NotificationManager.error(
    mes,
    name,
    3000,
    null,
    null,
    'filled',
  ),
};

export const validateExternalApiToken = (apiTokenPayload) => {
  const fieldsErrors = {};
  if (!apiTokenPayload.clinic_id) {
    fieldsErrors.clinic_id = 'Please choose clinic';
  }
  if (!apiTokenPayload.request_limit_count) {
    fieldsErrors.request_limit_count = 'Please enter total request limit';
  }
};

/**
 * Constrants related to billing codes
 */
export const billingCodeStatus = {
  MAX_VALUE: 99999,
  MIN_VALUE: 0,
  MAX_LENGHT: 5,
};
