import axios from 'axios';
import fingerprint from 'browser-fingerprint';
import { NotificationManager } from '../components/common/react-notifications';
import localStorageKeys from './localStorageKeys';

const axiosService = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

const token = localStorage.getItem(localStorageKeys.moichorToken);
axiosService.defaults.headers.common.Authorization = (token) ? `Bearer ${token}` : null;

axiosService.interceptors.request.use(async (req) => {
  if ((req.method === 'post' || req.method === 'patch') && req.data) {
    Object.keys(req.data).forEach((k) => {
      req.data[k] = typeof req.data[k] === 'string' ? req.data[k].trim() : req.data[k];
    });
  }
  // eslint-disable-next-line no-prototype-builtins
  if (req.method === 'get' && req.hasOwnProperty('params')) {
    Object.keys(req.params).forEach((k) => {
      req.params[k] = typeof req.params[k] === 'string' ? req.params[k].trim() : req.params[k];
    });
  }
  return req;
});

axiosService.interceptors.response.use(
  (res) => res,
  async (error) => {
    const { message, name } = error.response.data.error;
    if (name === 'RenewSessionError' || message === 'JsonWebTokenError') {
      localStorage.removeItem(localStorageKeys.moichorToken);
      localStorage.removeItem(localStorageKeys.moichorUser);
      localStorage.removeItem(localStorageKeys.moichorRefreshToken);
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } else if (message === 'TokenExpiredError') {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem(localStorageKeys.moichorRefreshToken);
      const accessToken = localStorage.getItem(localStorageKeys.moichorToken);

      if (originalRequest.data) {
        if (originalRequest.params && originalRequest.params.cf_type && originalRequest.params.cf_type === 8);
        else originalRequest.data = JSON.parse(originalRequest.data);
      }
      const code = fingerprint();
      try {
        const { data, response: err } = await axios.patch(`${process.env.REACT_APP_BASE_API}/token`, { refreshToken, accessToken, code });
        if (err || !data) return Promise.reject(error.response);
        if (data) {
          const { newAccessToken, newRefreshToken } = data;
          localStorage.setItem(localStorageKeys.moichorToken, newAccessToken.token);
          localStorage.setItem(localStorageKeys.moichorRefreshToken, newRefreshToken.token);

          axiosService.defaults.headers.common.Authorization = `Bearer ${newAccessToken.token}`;
          originalRequest.headers.Authorization = `Bearer ${newAccessToken.token}`;

          return new Promise((resolve, reject) => {
            axios(originalRequest)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        }
      } catch (error) {
        localStorage.removeItem(localStorageKeys.moichorToken);
        localStorage.removeItem(localStorageKeys.moichorUser);
        localStorage.removeItem(localStorageKeys.moichorRefreshToken);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
    const notification = (typeof message === 'object' && Object.prototype.hasOwnProperty.call(message, 'name')) ? message.name : message;
    NotificationManager.error(notification, name, 3000);
    return Promise.reject(error.response);
  },
);

export default axiosService;
