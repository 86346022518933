import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/css/sass/themes/gogo.light.blue.scss'
// // import 'react-table/react-table.css';

import {
  defaultColor,
  themeColorStorageKey,
  isDarkSwitchActive,
} from './constants/defaultValues';
import localStorageKeys from './constants/localStorageKeys';
import axios from './constants/axiosService';

const color = (isDarkSwitchActive) && localStorage.getItem(themeColorStorageKey)
  ? localStorage.getItem(themeColorStorageKey)
  : defaultColor;
localStorage.setItem(themeColorStorageKey, color);

const token = localStorage.getItem(localStorageKeys.moichorToken);
if (token) {
  axios.defaults.headers.common.Authorization = (token) ? `Bearer ${token}` : null;
}

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    // eslint-disable-next-line
    require('./AppRenderer');
  });
};

render();
