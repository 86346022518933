import React, {
  createContext, useReducer, useContext, useMemo, useCallback,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from '../../constants/axiosService';
import reducer, { INIT_STATE } from './reducer';
import {
  loginUserSuccess,
  loginUserError,
  loginUserSuccess2fa,
  loginUserError2fa,
  loginUser,
  loginUser2fa,
  logoutUser,
  getCurrentUser as getCurrentUserAction,
} from './actions';
import { statuses2fa } from '../../constants/static';
import localStorageKeys from '../../constants/localStorageKeys';

const BASE_API = process.env.REACT_APP_BASE_API;

const UserContext = createContext({});
export const useUserContext = () => useContext(UserContext);

const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const history = useHistory();
  const location = useLocation();

  const allPermissions = useMemo(() => {
    if (state?.user?.permissions && state?.user?.Role?.permissions) {
      return [...state.user.permissions, ...state.user.Role.permissions];
    }
    return [];
  }, [state]);

  const usePermission = useCallback((permission) => allPermissions.includes(permission), [allPermissions]);
  const getPermission = useCallback((permission) => allPermissions.includes(permission), [allPermissions]);

  const setLocalData = ({ accessToken = {}, refreshToken = {} }, user) => {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken.token}`;
    localStorage.setItem(localStorageKeys.moichorToken, accessToken.token);
    localStorage.setItem(localStorageKeys.moichorRefreshToken, refreshToken.token);
    localStorage.setItem(localStorageKeys.moichorUser, JSON.stringify(user));
  };

  const clearLocalData = () => {
    axios.defaults.headers.common.Authorization = null;
    localStorage.removeItem(localStorageKeys.moichorToken);
    localStorage.removeItem(localStorageKeys.moichorRefreshToken);
  };

  const loginWithEmailPassword = async (user) => {
    const {
      email, password, code, recaptchaResponse,
    } = user;
    dispatch(loginUser(user));
    try {
      const { data } = await axios.post(`${BASE_API}/signin`, {
        email, password, code, recaptchaResponse,
      });
      if (data) {
        const { user, authData } = data;
        if (user && authData && authData.accessToken) {
          setLocalData(authData, user);
          dispatch(loginUserSuccess(user));
          history.push((location.state && location.state.from.pathname) || '/');
        } else if (user && user.enabled_2fa === statuses2fa.PHONE) {
          dispatch(loginUserSuccess(user));
          history.push('/user/auth/2fa-phone');
        } else if (user && authData && authData.dataUrl) {
          dispatch(loginUserSuccess(user));
          localStorage.setItem(localStorageKeys.moichorQRCode, authData.dataUrl);
          history.push('/user/auth/2fa');
        } else if (user && authData && !authData.dataUrl) {
          dispatch(loginUserSuccess(user));
          localStorage.removeItem(localStorageKeys.moichorQRCode);
          history.push('/user/auth/2fa');
        }
      }
      if (!data) dispatch(loginUserError('Error.'));
    } catch (error) {
      dispatch(loginUserError(error.data.error.message));
    }
  };

  const loginWithCode = async (user) => {
    const { code, id, phoneNumber = null } = user;
    dispatch(loginUser2fa(user));
    try {
      const { data } = await axios.post(`${BASE_API}/signin-2fa`, { code, id, phoneNumber });

      if (data) {
        const { user, verified, authData } = data;
        if (verified) {
          if (authData) {
            setLocalData(authData, user);
            dispatch(loginUserSuccess2fa(user));
            localStorage.removeItem(localStorageKeys.moichorQRCode);
            history.push('/');
          }
        } else if (!verified) {
          dispatch(loginUserError2fa('The code was not verified. Please, try again.'));
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('login error : ', error);
    }
  };

  const logout = () => {
    dispatch(logoutUser());
    try {
      dispatch(loginUserSuccess(null));
      clearLocalData();
      history.push('/');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('logout failed :', error);
    }
  };

  const getCurrentUser = async () => {
    dispatch(getCurrentUserAction());
    try {
      const { data } = await axios.get(`${BASE_API}/v1/user/current`);
      if (data) {
        dispatch(loginUserSuccess(data));
      } else {
        dispatch(loginUserSuccess(null));
        clearLocalData();
        history.push('/');
      }
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('login failed :', error);
      return Promise.reject(error);
    }
  };

  return (
    <UserContext.Provider value={{
      ...state,
      loginWithEmailPassword,
      loginWithCode,
      logout,
      getCurrentUser,
      usePermission,
      getPermission,
    }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const withUserContext = (Component) => (
  function (props) {
    return (
      <UserContext.Consumer>
        {(state) => <Component {...props} {...state} />}
      </UserContext.Consumer>
    );
  }
);

export default UserContextProvider;
